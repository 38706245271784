$font-size-base: 0.96rem;
$theme-colors: (
  "primary": #0074d9,
  "danger": #f0166f
);

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

$theme-colors: map-remove($theme-colors, "info", "light", "dark");
$price_color: desaturate($red, 20%);
$light: $gray-300;

@import "../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../../node_modules/bootstrap/scss/bootstrap";
//@import 'style';

body {
  background: $gray-200;
}
a {
  color: $red;
}

.card {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}

input.form-control {
  &.form-control-xlg {
    height: calc(2.4em + 0.55rem + 2px);
    font-size: 1.3rem;
  }
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.09);
  background: $gray-100;
}

.global-warning {
  background: red;
  padding: 10px 0px;
  color: #fff;
  font-size: 14px;
}
#content {
  margin-bottom: 20px;
}
footer {
  margin-bottom: 20px;
}
#bodyLogin {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 100px;
  .shadow-sm {
    margin: 0 20px;
  }
  .login-bg {
    background: #fff;
    position: relative;
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  /*background: rgba(255,255,255,0.85);*/
  width: 100%;
  height: 100%;
  z-index: 1100;
  .container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
}

.exception-container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1100;
  .container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .bottombar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1090;
    box-shadow: 0 -0.05rem 0.55rem rgba(0, 0, 0, 0.075) !important;
    .row {
      .nav-item {
        text-align: center;
        a {
          display: block;
          box-sizing: border-box;
          padding: 10px 0px;
          color: #ccc;
          &.selected {
            border-top: 3px solid $red;
            background: $gray-200;

            svg {
              color: $black !important;
            }
          }
        }
      }
    }
  }
}

.price {
  color: $price_color;
  font-weight: 550;
  > span {
    padding-right: 5px;
  }
  > del {
    color: $black;
  }
}

.productPage {
  .list-group-item {
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
    position: relative;
    .card-text {
      margin-bottom: 0;
    }
    .list-checked {
      position: absolute;
      top: 20%;
      right: 20px;
      font-size: 20px;
    }
  }
}

.form-search {
  background: $gray-300;
  &:focus {
    background: $white;
  }
}

.form-control-single-large {
  background: $gray-300;
  font-size: 17px;

  font-weight: normal;
  &:not(:placeholder-shown) {
    background: $white;
    font-weight: bold;
  }
}


.message {
  transform: scale(1);
}
.message-enter {
  opacity: 0.01;
  transform: translateY(-20%) scale(1);
}
.message-enter-active {
  opacity: 1;
  transform: translateY(0%) scale(1);
  transition: all 300ms ease-out;
}
.message-exit {
  opacity: 1;
  transform: scale(1);
}
.message-exit-active {
  opacity: 0;
  transform: translateX(40%) scale(1);
  transition: all 300ms ease-in;
}

.cardanim-enter {
  opacity: 0.01;
  transform: translateY(-20%) scale(1);
}
.cardanim-enter-active {
  opacity: 1;
  transform: translateY(0%) scale(1);
  transition: all 300ms ease-out;
}
.cardanim-exit {
  opacity: 1;
  transform: scale(1);
}
.cardanim-exit-active {
  opacity: 0;
  transform: translateX(40%) scale(1);
  transition: all 300ms ease-in;
}

.subdetail {
  .row {
    border-top: 1px solid #565d64 !important;
  }
}

.add-button {
  position: absolute;
  bottom: 5px;
  margin: 0 auto;
  width: 90%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  background: #ddd;
  color: #333;
  border: none;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/** Nav */
.nav-pills {
  li {
    margin-right:10px;
    a {
      color:#0074d9;
      padding:0.5rem 1.4rem;
    }
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .bottombar {
    display: none;
  }
}

@media (max-width: 576px) {
  .dashboardPage {
    .container {
      .card {
        .card-body {
          //padding:10px;
          > .row {
            > div {
              //padding:0
            }
          }
        }
      }
    }
  }
}
